import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CardI } from 'src/app/model/card.interface';

import { FireBaseService } from 'src/app/service/fire-base.service';
@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styles: ['']
})

export class HomeComponent implements OnInit{
  viewImage : boolean = false;   

  @ViewChild('lImage', {static: true}) lImage : ElementRef;
    
    public titulo;
    public subtitulo;
    public active;
    public size;
    public urlImage;
    loading = true;
    card: CardI[] = [];
    cards : Array<CardI>;
    constructor( private firabase: FireBaseService){
        this.titulo = 'Junior Developer';
        this.subtitulo = 'Desarrollador de sitios y aplicaciones web';
        this.active = 'active';
        this.size = 'headerIndex';
        this.urlImage = "https://torttugacrush.github.io/img/img/svg/isotipo/LogoCrush.png";

      //console.log(this.TarjetasImgTitulo);
    }
    ngOnInit(): void {
      this.loadCard();       
    }
    loadCard() {
      this.firabase.getCard()
        .subscribe((result) => {
          this.loading = false;
          this.card = result;
        })
    }


    niveles: any[] = [ { nivel: 'E', nombresNivel: 'Experto' }, { nivel: 'A', nombresNivel: 'Avanzado' }, { nivel: 'I', nombresNivel: 'Intermedio' }, { nivel: 'N', nombresNivel: 'Principiante' }, { nivel: 'F', nombresNivel: 'Fundamento' }];
    
  

}
