import { Component, OnInit } from '@angular/core';
import { ArticuloService } from 'src/app/service/articulo.service';

@Component({
    selector: 'footerc',
    templateUrl: './footerc.component.html',
    styles: [`
      footer{  
        background-color: rgb(19, 52, 100); 
        color:aliceblue;
        bottom: 1;   
     }
    `]

})
export class FootercComponent implements OnInit{

    constructor(private service: ArticuloService){  
    }
  ngOnInit(): void {
  this.service.getOne()
  .subscribe(
    data => {
    },
    error =>{
    }
  )
  }
}
