import { Component, OnInit } from '@angular/core';
import { ProyectI } from 'src/app/model/proyect.interface';
import { FireBaseService } from 'src/app/service/fire-base.service';

@Component({
    selector: 'proyect',
    templateUrl: './proyect.component.html',
    styles: ['']
})

export class ProyectComponent implements OnInit {
 
    
    public titulo;
    public active;
    public size;
    loading = true;
    proyect : Array<ProyectI>;
    constructor(private firabase: FireBaseService){
        this.titulo = 'Proyectos';
        this.active = 'active';
        this.size = 'headerG';
        this.proyect = [];
    
    }
    ngOnInit(): void {
      this.loadProyect();       
    }
    loadProyect() {
      this.firabase.getProyect()
        .subscribe((result) => {
          this.loading = false;
          this.proyect = result;
        })
    }


}

