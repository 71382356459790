import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AboutI } from '../model/about.interface';
import { CardI } from '../model/card.interface';
import { ProyectI } from '../model/proyect.interface';

@Injectable({
  providedIn: 'root'
})
export class FireBaseService {
  constructor(private firestore: AngularFirestore) { 
  }

  public getCard(){
    return this.firestore.collection<CardI>('card', x => x.orderBy('order', 'asc')).valueChanges();
  }
  public getAbout(){
    return this.firestore.collection<AboutI>('about').valueChanges();
  }
  public getProyect(){
    return this.firestore.collection<ProyectI>('proyect', x => x.orderBy('order', 'asc')).valueChanges();
  }
}
