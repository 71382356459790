import { Component, OnInit} from '@angular/core';
import { AboutI } from 'src/app/model/about.interface';
import { FireBaseService } from 'src/app/service/fire-base.service';


@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styles: ['']

})

export class AboutComponent implements OnInit {

  
    public titulo;
    public active;
    public size;
    public ga;
    about : AboutI[] = [];
    loading = true;
    constructor( private firabase: FireBaseService){
        this.titulo = 'Acerca de mí';
        this.active = 'active';
        this.size = 'headerG';

    }
    ngOnInit(): void {
      this.loadAbout();  
        
    
  }
  loadAbout() {
    this.firabase.getAbout()
      .subscribe((result) => {
        this.loading = false;
        this.about = result;
      })
  }

}
