import { Component } from '@angular/core';

@Component({
  selector: 'app-cargas',
  template: `<div class="sk-folding-cube">
  <div class="sk-cube1 sk-cube"></div>
  <div class="sk-cube2 sk-cube"></div>
  <div class="sk-cube4 sk-cube"></div>
  <div class="sk-cube3 sk-cube"></div>
</div>
`,
  styleUrls: ['./cargas.component.scss']
})
export class CargasComponent {

  constructor() { }

}
