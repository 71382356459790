import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import { NgxGistModule } from 'ngx-gist/dist/ngx-gist.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FireBaseService } from './service/fire-base.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { ProyectComponent } from './components/proyect/proyect.component';
import { AboutComponent } from './components/about/about.component';
import { HomeComponent } from './components/home/home.component';


import { NavheaderComponent } from './components/navheader/navheader.component';
import { NavfoneotherComponent } from './components/navfoneother/navfoneother.component';
import { NavotherComponent } from './components/navother/navother.component';
import { FootercComponent } from './components/footerc/footerc.component';



import { ArticuloComponent } from './components/articulo/articulo.component';
import { CargasComponent } from './components/cargas/cargas.component';
import { LoadingimgComponent } from './components/loadingimg/loadingimg.component';
import { ContactComponent } from './components/contact/contact.component';



@NgModule({
  declarations: [
    AppComponent,
    ArticuloComponent,
    AppComponent,
    HomeComponent,
    AboutComponent,
    ProyectComponent,
    NavotherComponent,
    NavfoneotherComponent,
    NavheaderComponent,
    FootercComponent,
    CargasComponent,
    LoadingimgComponent,
    ContactComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    BrowserAnimationsModule, // for database

    MatInputModule,
    MatButtonModule,
    MatIconModule,

    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGistModule,

  ],
  providers: [
    FireBaseService,
    AngularFirestore
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
