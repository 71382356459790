import { Component } from '@angular/core';
import { Contact } from 'src/app/models/contact';
import { ArticuloService } from 'src/app/service/articulo.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  public titulo = 'Contacta me ';
  public active = 'active';
  public size = 'headerG';
  public status: string;
  public retusData : String;
  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public contact : Contact; 
  public contactForm: FormGroup;
  public bul : boolean;
  public bulerror : boolean;
  public msnresponse : String;
  constructor( private _ArticuloService: ArticuloService) {
    this.contact = new Contact('','','','');
    this.contactForm = this.createFormGroup();
    this.bul = false;
    this.bulerror = false;
    this.msnresponse = "";
   }

  get email()    {return this.contactForm.get('email');}
  get name()     {return this.contactForm.get('name');}
  get subject()  {return this.contactForm.get('subject');}
  get message()  {return this.contactForm.get('message');}

  createFormGroup() {
    return new FormGroup({
      email:   new FormControl('', [Validators.required, Validators.minLength(5)/*, Validators.email*/
                               , Validators.pattern(this.emailPattern)]),
      name:    new FormControl('', [Validators.required, Validators.minLength(3)]),
      subject: new FormControl('', [Validators.required, Validators.minLength(4)]),
      message: new FormControl('', [Validators.required, Validators.minLength(5)
                               , Validators.maxLength(100)]),

    })
  }

  onSaveForm() {
 
      if (this.contactForm.valid) {
        this.contact =new Contact(
      this.contactForm.value.email,
      this.contactForm.value.name,
      this.contactForm.value.subject,
      this.contactForm.value.message);
      this.save(); 
    }
    console.log(this.contactForm.value.email);
    console.log(this.contactForm.value.name);
    console.log(this.contactForm.value.subject);
    console.log(this.contactForm.value.message);
  }
  save(){
      this._ArticuloService.postContact(this.contact)
      .subscribe(
        data => {
          console.log(data);
          this.bul = true;
          this.msnresponse = data.data;
       //   this.onResetForm();
        },
        
        error =>{
          this.bulerror = true;
          this.msnresponse = " Error no se envio el mensaje!. Enviame un mensaje a mi mail: ibanezmarcos0@gmail.com";
          console.log(error);
        }
       
      )
    }
 /*   onResetForm() {
      this.contactForm.reset();
    }*/
}
