
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-loadingimg',
  templateUrl: './loadingimg.component.html',
  styleUrls: ['./loadingimg.component.scss']
})
export class LoadingimgComponent implements OnInit {
  // Esta variable nos dara el control si debemos de mostrar la imagen o no debemos de mostrarla.
  // @value: false = la imagen no se cargo (se muestra aun el loading).
  // @value: true  = la imagen se cargo , desaparece el loading y muestra la imagen.
  viewImage : boolean = false;   
  heightimg : String;
  widthimg  : String;
  linkimg  : String;
  @Input()  heightInput: String;
  @Input()  widthInput: String;
  @Input()  linkInput: String;

  // Escuchamos constantemente si nuestro valor de entrada cambia.

  // Obtenemos una referencia hacia el tag "<img>" para poder manipularlo luego
  @ViewChild('lImage', {static: true}) lImage : ElementRef;
  
  constructor() { 
    this.heightimg = '';
    this.widthimg = '';
    this.linkimg ='';
  }

  ngOnInit() {
  // Utilizaremos el evento "onload" de el tag "<img>" ,este evento se disparara
  // cuando la imagen se carge en su totalidad.
      this.linkimg = this.linkInput;
      this.lImage.nativeElement.onload=()=>{
        this.heightimg = this.heightInput;
        this.widthimg  = this.widthInput;
        this.viewImage=true;                        
      }
  }

}